.pushups
	background-color: #cac2b8

	.pushups-ancestor
		@media (screen and min-width: 768px)
			height: 85vh

		.tile.is-child
			background-size: cover
			background-position: bottom
			background-repeat: no-repeat
			padding: 0

		.embed
			width: 100%
			height: 100%
			border-radius: 6px

			min-height: 45vh

.AboutIntro
	.is-large-numeral
		font-size: 7em

	.title
		text-transform: uppercase

	.column
		text-align: center

	.intro-list
		display: flex
		justify-content: center
		align-items: center

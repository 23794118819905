@charset "utf-8"

// import initial bulma stuff
@import "bulma/sass/utilities/initial-variables.sass"
@import "bulma/sass/utilities/functions.sass"

$family-sans-serif: almaq-refined, sans-serif
$primary: #123456

.is-caps
	text-transform: uppercase

// html,
// body
// 	height: unset !important
// 	min-height: unset !important
// 	overflow-y: unset !important

// import the rest of bulma
@import "bulma/bulma.sass"

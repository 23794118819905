.banner
	background-position: center
	background-size: cover

	@media (screen and min-width: 930px)
		.is-hashtag
			font-size: 8rem !important

		.is-byline
			font-size: 4rem !important

	.is-byline
		text-transform: uppercase

	.arrow-container
		padding: 3rem
		display: flex
		justify-content: center
		align-items: center

	.logo-top
		height: 50vh
		z-index: -1
